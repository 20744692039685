import React from "react";
import '../styles.css';

export default function Header(){
    return(
    <div className="header">
        <img className="logo" src='MovieLibrary.jpg' alt="Movie Library" />
        <h2 className="app-subtitle">It's time for pocorn! Find your movie here</h2>
    </div>
    )
}